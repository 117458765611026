import React from 'react';
import MenuAppBar from './../../Components/Navbar/navbar';
import { Accordion, Card, Button, Row, Container, Col, Spinner } from 'react-bootstrap';
import IdentityCard from './../../components/IdentityCard';
import { GrFormDown } from "react-icons/gr";
import FirebaseApp from "./../../config/firebase";
import { navigate } from 'gatsby'; //import navigate from gatsby
import { Link } from "gatsby"
import axios from 'axios';
import { saveAs } from 'file-saver';
import { RiUploadCloudFill, RiDownload2Line } from "react-icons/ri";
import QRCode from 'qrcode.react';

import './index.css'
let database = FirebaseApp.database().ref("/");

export default class AdmitCard extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "suneel kumar",
            fam: "52465324",
            price2: 544564,
            receiptId: "one",
            appliedCourse: [],
            user: {},
            isLoader: false
        }
    }


    componentDidMount() {
        let arr = []
        FirebaseApp.auth().onAuthStateChanged(user => {
            if (user && user.emailVerified) {
                database.child("Registration/" + user.uid).once('value', res => {
                    let data = res.val();
                    if (data.approved === "Approved") {
                        this.setState({ user: data })
                    }
                })
            }
            else {
                navigate('/');

            }
        })

    }

    createAndDownloadPdf = () => {
        this.setState({ isLoader: true })
        let { user } = this.state
        let obj = {
            name: user.name,
            fname: user.fatherName,
            course: user.course,
            rollNumber: user.rollNumber,
            url: user.url
        }
        axios.post('https://chalangenerator.herokuapp.com/create-pdf', obj)
            .then(() => axios.get('https://chalangenerator.herokuapp.com/fetch-pdf', { responseType: 'blob' }))
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                saveAs(pdfBlob, 'iskillersAdmitCard.pdf');
                this.setState({ isLoader: false })
            })
    }



    render() {
        let { appliedCourse, user } = this.state
        return (
            <div >
                <MenuAppBar />
                <Container fluid>
                    <IdentityCard data={user} src={"https://chart.googleapis.com/chart?cht=qr&chl=" + user.rollNumber + "&chs=160x160&chld=L|0"} />
                    <p style={{ textAlign: "center" }}>

                        <button className="_downloadBtn" onClick={() => this.createAndDownloadPdf()} style={{ width: 250, fontSize: 12 }}>
                            {
                                this.state.isLoader ? <><Spinner as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" /> &nbsp; &nbsp; Downloading....</> :
                                    "DOWNLOAD IDENTITY CARD"}
                        </button>
                    </p>
                </Container>
            </div>
        )
    }
}