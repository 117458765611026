import React from "react";
import './styles.css'; //Import here your file style
import Islillers_logo from './../../images/colorlogo-01.png'

class IdentityCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            src: "https://chart.googleapis.com/chart?cht=qr&chl="+123+"&chs=160x160&chld=L|0"
        }
    }
    render() {
        let data = this.props.data
        return (
            <div style={{ textAlign: "center" }}>
                <div className="_Main_Div">
                    <div className="_Id_Card_Main">
                        <img src={Islillers_logo} className="_iskillers_logo_for_identity_card" />
                        <div className="_iskillers_website_link">
                            <p className="_iskilleres_link">STUDENT ADMIT CARD</p>
                        </div>

                        <div className="_User_Data_Main">
                            <div className="_User_Data">
                                <table>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{data.name}</td>
                                    </tr>

                                    <tr>
                                        <td>Roll No:</td>
                                        <td>{data.rollNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Course:</td>
                                        <td className="_courseTd"> {data.course}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "36%" }}>Authorized Sign:</td>
                                        <td>_______________</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="_User_Profile">
                                <img src={data.url} className="_profile_picture"
                                    alt="Profile Picture" />
                            </div>
                        </div>

                        <div className="_iskillers_website_link">
                            <div className="_iskilleres_link">www.ihubar.com</div>
                        </div>


                    </div>

                    {/* BACK SIDE */}
                    <div className="_Id_Card_Main">

                        <div className="_User_Data_Main" style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                            <img src={Islillers_logo} className="_iskillers_logo_for_identity_card" />
                            <div className="_User_Profile">
                                <img src={this.props.src}
                                    className="_profile_picture" alt="Profile Picture" />
                            </div>

                            <div className="_iskillers_website_link" style={{ width: "100%", marginBottom: 25 }}>
                                <div className="_iskilleres_link" >www.ihunar.com</div>
                            </div>

                        </div>


                    </div>
                    {/* PRINT BUTTON */}
                </div>
            </div>
        );
    }
}
export default IdentityCard;